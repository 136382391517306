<template>
  <div class="all-height">
    <floadingPageHeader
      :title="data.name || ''"
      icon="mdi-card-account-details-outline"
      :loading="loading"
    >
      <template v-slot:content>
        <v-card class="shadow-off">
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="6">
                <v-card class="shadow-off hoverable view-on-hover-area">
                  <v-card-text>
                    <div class="d-flex align-center border-left-primary mb-2">
                      <span class="title font-weight-thin pl-2"
                        >Basic Information</span
                      >
                      <v-spacer></v-spacer>
                    </div>
                    <div class="my-4">
                      <div class="grey--text">Contact Person</div>
                      <div class="">{{ data.contactperson || "" }}</div>
                    </div>
                    <div class="my-4">
                      <div class="grey--text">Contact Number</div>
                      <div class="">{{ data.contactno || "" }}</div>
                    </div>
                    <div class="my-4">
                      <div class="grey--text">Contact Email</div>
                      <div class="">{{ data.contactemail || "" }}</div>
                    </div>
                    <div class="my-4">
                      <div class="grey--text">Address</div>
                      <div class="">{{ data.address || "" }}</div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </floadingPageHeader>
  </div>
</template>

<script>
// @ is an alias to /src
import floadingPageHeader from "@/views/common/FloatingPageHeader";

export default {
  name: "profileinfo",
  // props: ['props'],
  data: function () {
    return {
      pageprops: {
        name: "Client",
        breadcrumbs: [],
      },
      data: {},
      addflag: false,
      editflag: false,
      loading: false,
    };
  },
  components: {
    floadingPageHeader,
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.errors = {};
      this.addflag = false;
      this.editflag = false;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.axios
        .post("/v2/profile/getorganisation")
        .then((dt) => {
          console.log(dt.data);
          if (dt.data.status === "success") {
            if (dt.data.data.length > 0) this.data = dt.data.data[0];
            else throw new Error("No data found");
          } else {
            throw new Error("Error fetching data");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {},
  watch: {
    "$store.state.client": function () {
      this.refreshData();
    },
  },
};
</script>

